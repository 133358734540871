import React from 'react';
import cn from 'classnames';
import { LangIcon } from '@runity/ui-icons';
import { Link } from '@runity/ui-link';
import { Text } from '@runity/ui-text';
import { Lang } from '@rcdev/api-bff-parking';

import styles from './footer.pcss';

interface IFooterProps {
  lang: Lang;
  domain?: string;
  homeLink: { en: string; ru: string };
  queryLangSwitch?: boolean;
}

const menuRu = [
  {
    title: 'Регистрация доменов',
    url: '/catalog/domains/',
  },
  {
    title: 'Хостинг для сайтов',
    url: '/catalog/hosting/',
  },
  {
    title: 'Виртуальный хостинг',
    url: '/catalog/hosting/shared/',
  },
  {
    title: 'VPS/VDS хостинг',
    url: '/catalog/hosting/vds-vps/',
  },
  {
    title: 'Аренда сервера',
    url: '/catalog/hosting/dedicated/',
  },
  {
    title: 'SSL-сертификаты',
    url: '/catalog/ssl/',
  },
];

// Обратить внимание что ссылки в menuEn НЕ СОДЕРЖАТ указания языка
// Язык в них подставляется в зависимости от языка текущей страницы expired
const menuEn = [
  {
    title: 'Domain Registration',
    url: '/catalog/domains/',
  },
  {
    title: 'Hosting',
    url: '/catalog/hosting/',
  },
  {
    title: 'Shared Hosting',
    url: '/catalog/hosting/shared/',
  },
  {
    title: 'VDS/VPS virtual servers',
    url: '/catalog/hosting/vds-vps/',
  },
  {
    title: 'Dedicated servers',
    url: '/catalog/hosting/dedicated/',
  },
  {
    title: 'SSL certificates',
    url: '/catalog/ssl/',
  },
];

const getMenuByLang = (lang: Lang) => {
  if (lang === Lang.En) {
    return menuEn;
  }

  return menuRu;
};

// eslint-disable-next-line arrow-body-style
const concatUrl = (homeUrl: string, path: string): string => {
  if (path.startsWith('http')) {
    return path;
  }

  return `${homeUrl.endsWith('/') ? homeUrl.slice(0, -1) : homeUrl}${path.startsWith('/') ? path : `/${path}`}`;
};

const getLangSwitchUrl = (props: IFooterProps) => {
  const { lang, domain, queryLangSwitch } = props;

  if (queryLangSwitch) {
    return `/?lang=${lang === Lang.Ru ? 'en' : 'ru'}&domain=${domain}`;
  }

  return lang === Lang.Ru ? '/en/' : '/';
};

export const Footer = (props: IFooterProps) => {
  const { lang, homeLink } = props;

  return (
    <div className={styles.footer}>
      <div className={styles.footerContentWrapper}>
        <div className={styles.footerContent}>
          <Link
            href={getLangSwitchUrl(props)}
            className={styles.footerLang}
            underlined="hover"
            iconMargin="right"
          >
            <LangIcon />
            <Text preset="textSmall">{`${lang === Lang.Ru ? 'English' : 'Русский'}`}</Text>
          </Link>
          <span className={cn(styles.footerCopyright)}>
            <Text color="tertiaryMain" preset="textSmall">
              {`© АО «РСИЦ» (Руцентр), ${new Date().getFullYear()}`}
            </Text>
          </span>
        </div>
        {/* eslint-disable react/no-array-index-key */}
        <ul className={styles.footerMenu}>
          {getMenuByLang(lang).map((item, index) => (
            <li key={`menu-${lang}-${index}`}>
              <Link
                href={concatUrl(homeLink[`${lang}`], item.url)}
                key={item.title}
              >
                <Text preset="textSmall">{item.title}</Text>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
