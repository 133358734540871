import React from 'react';
import cn from 'classnames';
import { Text } from '@runity/ui-text';

import { Link } from '../../components/Link';
import { PageBlocksContext, TPageBlocksContext } from '../../contexts/PageBlocksContext';
import { IImageProps, ILinkProps } from '../types';

import styles from './parkingPageBlock.pcss';

interface IContentBlockParkingPageBlockProps {
  title: string;
  description: string;
  image: IImageProps;
  link?: ILinkProps;
  columns?: TPageBlocksContext;
}

export const ParkingPageBlock = (props: IContentBlockParkingPageBlockProps) => {
  const { title, description, link, image, columns } = props;

  return (
    <PageBlocksContext.Consumer>
      {(value) => (
        <div className={cn(styles.parkingPageBlock, styles[`parkingPageBlock-${columns || value}-column`])}>
          <div className={styles['parkingPageBlock-content']}>
            <div className={styles['parkingPageBlock-title']}>
              <Text preset="titleH4">{title}</Text>
            </div>
            {description &&
              <Text className={styles['parkingPageBlock-description']} color="secondaryMedium" html>
                {description}
              </Text>}
            {link &&
              <div className={styles['parkingPageBlock-btn']}>
                <Link {...link} size="s" />
              </div>}
          </div>
          {image &&
            <div className={styles['parkingPageBlock-icon']}>
              <img src={image.url} alt={image.alt} />
            </div>}
        </div>
      )}
    </PageBlocksContext.Consumer>
  );
};
