import React, { Component } from 'react';

import packageJson from '../../package.json';
import { Scripts } from '../server/Scripts';
import { IPageContext } from './BasePage';

export interface IAppProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Page: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pageProps: any;
  isClient: boolean;
}

export interface IAppInitialProps {
  pageProps: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    manifest: any;
  };
}

export class App extends Component<IAppProps> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static async getInitialProps(context: IPageContext, page: any): Promise<IAppInitialProps> {
    // @ts-ignore
    const { manifest } = context.req;
    let pageProps = {};

    if (page.getInitialProps) {
      pageProps = await page.getInitialProps(context);
    }

    return { pageProps: { manifest, ...pageProps } };
  }

  public render() {
    const { Page, pageProps, isClient } = this.props;
    const Entry = isClient ? React.Fragment : 'div';
    const entryProps = isClient ? {} : { id: packageJson.name };

    return (
      <Entry {...entryProps}>
        <Scripts id={packageJson.name} page={Page} pageProps={pageProps} />
        <Page {...pageProps} />
      </Entry>
    );
  }
}
