/* eslint-disable @typescript-eslint/no-explicit-any, no-redeclare */
import { AxiosResponse } from 'axios';

function normalizeKey(key: string): string {
  return key
    .replace(/(_)(.)/g, (_str, _p1, p2) => p2.toUpperCase())
    .replace(/^(.)/, (_str, p1) => p1.toLowerCase());
}

function normalizeKeys<T extends {} | string | number | null>(value: T[]): T[];
function normalizeKeys<T extends {}>(value: T): T;
function normalizeKeys<T extends string | number | null>(value: T): T;
// typed in overload
function normalizeKeys(object: any): any {
  if (object instanceof Array) {
    return object.map(normalizeKeys);
  } if (typeof object === 'object' && !!object) {
    return Object.keys(object).reduce((a, v) => ({ ...a, [normalizeKey(v)]: normalizeKeys(object[v]) }), { });
  }

  return object;
}

/**
 * Переделывает входящие ключи из snake_case и PascalCase в camelCase
 * за наличие этого интерцептора спасибо "Вадиму Кулагину @vkulagin" и его прекрасной ручке
 */
export function camelInterceptor(response: AxiosResponse) {
  if (typeof response.data !== 'string') {
    response.data = normalizeKeys(response.data);

    return response;
  }

  return response;
}
