/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component } from 'react';
import { Request, Response } from 'express';

import { setupVersion } from './setupVersion';

export interface IPageContext {
  req: Request;
  res: Response;
  serverConfiguration: IServerConfiguration;
}

setupVersion();

export abstract class BasePage<P = {}, S = {}> extends Component<P, S> {
  public static displayName = '';

  // @ts-ignore - так public static abstract async нельзя
  // eslint-disable-next-line @typescript-eslint/require-await
  public static async getInitialProps(context: IPageContext) { return {}; }
}
