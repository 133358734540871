import { AxiosRequestConfig, AxiosResponse } from 'axios';

export function responseLogsInterceptor(response: AxiosResponse) {
  const respLogs = {
    type: 'response',
    url: `${response?.config?.baseURL}${response?.config?.url}`,
    status: response?.status,
    headers: response?.headers,
  };

  if (process.env.IS_SERVER) {
    // eslint-disable-next-line no-console
    console.log(JSON.stringify(respLogs, undefined, process.env.NODE_ENV === 'production' ? 0 : 2));
  }

  return response;
}

export function requestLogsInterceptor(request: AxiosRequestConfig) {
  const requestLogs = {
    type: 'request',
    url: `${request?.baseURL}${request?.url}`,
    headers: request?.headers,
  };

  if (process.env.IS_SERVER) {
    // eslint-disable-next-line no-console
    console.log(JSON.stringify(requestLogs, undefined, process.env.NODE_ENV === 'production' ? 0 : 2));
  }

  return request;
}
