import { Request } from 'express';
import { Configuration, ExpiredApi } from '@rcdev/api-bff-parking';

/**
 * Обёртка для api, нужна для передачи headers в bff
 */
export const getBffExpiredApi = (configuration: Configuration, req?: Request) => {
  if (process.env.IS_SERVER) {
    // Если передать заголовок "content-length", но не передать body, то на сервере мы получаем ошибку "Error: socket hang up".
    const { 'content-length': contentLength, ...headers } = req?.headers || {};

    return new ExpiredApi({ ...configuration, baseOptions: { headers } });
  }

  return new ExpiredApi(configuration);
};
