import { ThunkMiddleware } from 'redux-thunk';

/**
 * Will be used for sentry in future releases
 */
export function reduxCaptureMiddleware(): ThunkMiddleware {
  return () => (next) => (action) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return next(action);
    } catch (e) {
      // for logging needs
      // eslint-disable-next-line no-console
      console.error(e);
      throw e;
    }
  };
}
