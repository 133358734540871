import React, { FunctionComponent } from 'react';

import styles from './contentWrapper.pcss';

/**
  Блок-обёртка для стилизации боковых отступов
  * @param children
*/
export const ContentWrapper: FunctionComponent = ({ children }) => (
  <div className={styles.contentWrapper}>
    {children}
  </div>
);
