import React from 'react';
import { Text } from '@runity/ui-text';

import styles from './htmlContent.pcss';

interface IContentBlockHtmlContentProps {
  html: string;
}

export const HtmlContent = (props: IContentBlockHtmlContentProps) => {
  const { html } = props;

  return (
    <div className={styles.htmlContent}>
      <Text html>{html}</Text>
    </div>
  );
};
