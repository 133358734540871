import React from 'react';

import { ExpiredMainBlock } from '../../contentBlocks/ExpiredMainBlock/ExpiredMainBlock';
import { HtmlContent } from '../../contentBlocks/HtmlContent/HtmlContent';
import { ParkingPageBlock } from '../../contentBlocks/ParkingPageBlock/ParkingPageBlock';
import { TUpdatedContentBlock } from '../../utils/convertBlocksStructure';
import { LinesContentView } from '../LinesContentView';

export function renderBlocks(block: TUpdatedContentBlock) {
  switch (block.type) {
    case 'linesContentView':
      return <LinesContentView key={block.position} {...block} />;
    case 'expiredMainBlock':
      return <ExpiredMainBlock key={block.position} {...block.data} />;
    case 'htmlContent':
      return <HtmlContent key={block.position} {...block.data} />;
    case 'parkingPageBlock':
      return <ParkingPageBlock key={block.position} {...block.data} />;
    default:
      return null;
  }
}
