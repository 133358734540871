import React from 'react';
import cn from 'classnames';
import { LogoIcon } from '@runity/ui-icons';
import { Link as UILink } from '@runity/ui-link';
import { Lang } from '@rcdev/api-bff-parking';

import { ContentWrapper } from '../ContentWrapper';

import styles from './header.pcss';

interface IHeaderProps {
  lang: Lang;
  homeLink: { ru: string; en: string };
}

export const Header = (props: IHeaderProps) => {
  const { lang, homeLink } = props;

  return (
    <div className={styles.header}>
      <ContentWrapper>
        <UILink
          underlined="none"
          href={homeLink[`${lang}`]}
          className={styles.logoWrapper}
        >
          <LogoIcon lang={lang} className={cn(styles.logo, { [styles.logoEn]: lang === Lang.En })} />
        </UILink>
      </ContentWrapper>
    </div>
  );
};
