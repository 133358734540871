import React, { useContext } from 'react';

import { TUpdatedContentBlock } from '../../utils/convertBlocksStructure';

const defaultRenderBlocks = (): JSX.Element | null => null;

export const RenderBlocksContext =
  React.createContext<(block: TUpdatedContentBlock) => JSX.Element | null>(
    defaultRenderBlocks);

export const useRenderBlocksContext = () => {
  const context = useContext(RenderBlocksContext);

  if (context === defaultRenderBlocks) {
    throw new Error(
      'useRenderBlocksContext must be used within a RenderBlocksContext.Provider',
    );
  }

  return context;
};
