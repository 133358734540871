import React from 'react';
import { Text } from '@runity/ui-text';

import { Link } from '../../components/Link';
import { IImageProps, ILinkProps } from '../types';

import styles from './expiredMainBlock.pcss';

interface IContentBlockExpiredMainBlockProps {
  title: string;
  description: string;
  hint: string;
  image: IImageProps;
  link?: ILinkProps;
}

export const ExpiredMainBlock = (props: IContentBlockExpiredMainBlockProps) => {
  const { title, description, link, image } = props;

  return (
    <div className={styles['expiredMainBlock-wrapper']}>
      <div className={styles.expiredMainBlock}>
        <div className={styles['expiredMainBlock-content']}>
          <div className={styles['expiredMainBlock-title']}>
            <Text preset="titleH1" tag="h1" wrap="break-word">{title}</Text>
          </div>
          {description &&
            <Text
              className={styles['expiredMainBlock-description']}
              color="secondaryMedium"
              preset="textLarge"
              html
            >
              {description}
            </Text>}
          {link &&
            <div className={styles['expiredMainBlock-btn']}>
              <Link {...link} size="l" />
            </div>}
        </div>
        {image && <img className={styles['expiredMainBlock-image']} src={image.url} alt={image.alt} />}
      </div>
    </div>
  );
};
