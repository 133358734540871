/* eslint-disable  @typescript-eslint/no-explicit-any */
import { AnyAction, configureStore, Reducer, ReducersMapObject } from '@reduxjs/toolkit';

import { reduxCaptureMiddleware } from './middlewares';

export interface ICreateStoreOptions<S extends {} = any, E extends {} = any, A extends AnyAction = any> {
  state?: S;
  extraArgument?: E;
  reducer: Reducer<S, A> | ReducersMapObject<S, A>;
}

/**
 * Creates redux store on the both client and server
 * @param opts
 */
export function createStore<S extends {} = any, E extends {} = any>(opts: ICreateStoreOptions<S, E>) {
  const { state, reducer } = opts;

  return configureStore({
    reducer,
    preloadedState: state,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ thunk: { extraArgument: opts.extraArgument } })
      .concat(reduxCaptureMiddleware()),
  });
}
