import { createElement } from 'react';

export const getBaseFonts = () => (
  createElement('link', {
    href: 'https://www.nic.ru/scripts/css/font-faces.css',
    rel: 'stylesheet',
  })
);
/* eslint-enable max-len */

const favicons = [
  {
    tagName: 'meta',
    props: {
      name: 'msapplication-square70x70logo',
      content: 'favicon_70x70.png',
    },
  },
  {
    tagName: 'meta',
    props: {
      name: 'msapplication-square150x150logo',
      content: 'favicon_150x150.png',
    },
  },
  {
    tagName: 'meta',
    props: {
      name: 'msapplication-wide310x150logo',
      content: 'favicon_310x150.png',
    },
  },
  {
    tagName: 'meta',
    props: {
      name: 'msapplication-square310x310logo',
      content: 'favicon_310x310.png',
    },
  },
  {
    tagName: 'link',
    props: {
      rel: 'icon',
      type: 'image/png',
      href: 'favicon.png',
    },
  },
  {
    tagName: 'link',
    props: {
      rel: 'apple-touch-icon',
      type: 'image/png',
      href: 'favicon_192x192.png',
    },
  },
  {
    tagName: 'link',
    props: {
      rel: 'apple-touch-icon',
      type: 'image/png',
      href: 'favicon_192x192.png',
    },
  },
  {
    tagName: 'link',
    props: {
      rel: 'apple-touch-icon',
      type: 'image/png',
      sizes: '152x152',
      href: 'favicon_152x152.png',
    },
  },
  {
    tagName: 'link',
    props: {
      rel: 'apple-touch-icon',
      type: 'image/png',
      sizes: '180x180',
      href: 'favicon_180x180.png',
    },
  },
  {
    tagName: 'link',
    props: {
      rel: 'apple-touch-icon',
      type: 'image/png',
      sizes: '167x167',
      href: 'favicon_167x167.png',
    },
  },
  {
    tagName: 'link',
    props: {
      rel: 'icon',
      type: 'image/png',
      sizes: '192x192',
      href: 'favicon_192x192.png',
    },
  },
];

const getFaviconStaticPath = (favicon: string) => `/static/${favicon}`;

export const getFavIcons = () => favicons.map((obj, index) => createElement(obj.tagName, obj.tagName === 'meta' ? {
  ...obj.props,
  ...(obj.props.content ? { content: getFaviconStaticPath(obj.props.content) } : {}),
  key: `favicon-${index}`,
} : {
  ...obj.props,
  ...(obj.props.href ? { href: getFaviconStaticPath(obj.props.href) } : {}),
  key: `favicon-${index}`,
}));
