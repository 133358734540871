import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { name } from '../../package.json';
import { App } from './App';

export function makePageHydrate(Page: React.ReactNode) {
  if (process.env.IS_SERVER) {
    return;
  }

  window.addEventListener('load', () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    window[`__${name}_PAGE_PROPS__` as any] = JSON.parse(
      document.getElementById(`__${name}_PAGE_PROPS__`)!.textContent || '{}',
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const pageProps = window[`__${name}_PAGE_PROPS__` as any];
    // @ts-ignore
    const renderMethod = module.hot ? ReactDOM.render : ReactDOM.hydrate;

    renderMethod(
      React.createElement(App, { pageProps, Page, isClient: true }),
      document.getElementById(name),
    );
  });
}
