import { ContentBlock } from '@rcdev/api-bff-parking';

export type TUpdatedContentBlock = ContentBlock & {
  children?: TUpdatedContentBlock[][];
};

/**
  Функа для получение вложенных блоков
*/
function getNestedBlocks(currentBlock: TUpdatedContentBlock, blocks: TUpdatedContentBlock[]) {
  currentBlock.zoneDeclarations!.forEach((zone) => {
    const oneZoneBlocks = blocks.filter((item) => item.zone.name === zone.name);
    if (oneZoneBlocks.length > 0) {
      // eslint-disable-next-line no-param-reassign
      currentBlock.children = currentBlock.children ? [...currentBlock.children, oneZoneBlocks] : [oneZoneBlocks];

      oneZoneBlocks.forEach((nestedBlock) => {
        blocks.splice(blocks.findIndex((item) => item === nestedBlock), 1);
        if (nestedBlock.zoneDeclarations && nestedBlock.zoneDeclarations.length > 0) {
          getNestedBlocks(nestedBlock, blocks);
        }
      });
    }
  });
}

/**
  Функа для преобразования массива blocks из ответа bff'а в вид, удобный фронту
*/
export function convertBlocksStructure(blocks: ContentBlock[]): TUpdatedContentBlock[] {
  blocks.forEach((block) => {
    if (block.zoneDeclarations && block.zoneDeclarations.length > 0) {
      getNestedBlocks(block, blocks);
    }
  });

  return blocks;
}
