import { connect } from 'react-redux';

import { Header } from '../../components/Header';
import { IAppState } from '../../reducers';
import { getLang } from '../../utils/commonSelectors';

interface IHeaderContainerProps {
  homeLink: { ru: string; en: string };
}

function mapStateToProps(state: IAppState, ownProps: IHeaderContainerProps) {
  const lang = getLang(state);

  return {
    lang,
    homeLink: ownProps.homeLink,
  };
}

export const HeaderContainer = connect(mapStateToProps)(Header);
