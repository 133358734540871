import React, { FunctionComponent } from 'react';

import styles from './pageWrapper.pcss';

/**
  Блок-обёртка для прижатия футера к низу страницы
  * @param children
*/
export const PageWrapper: FunctionComponent = ({ children }) => (
  <div className={styles.pageWrapper}>
    {children}
  </div>
);
