import { connect } from 'react-redux';

import { ExpiredContent } from '../../components/ExpiredContent';
import { IAppState } from '../../reducers';
import { getPageBlocks } from '../../utils/commonSelectors';

function mapStateToProps(state: IAppState) {
  const blocks = getPageBlocks(state);

  return { blocks };
}

export const ExpiredContentContainer = connect(mapStateToProps)(ExpiredContent);
