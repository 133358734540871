/* eslint-disable  @typescript-eslint/no-explicit-any */
import { Action } from 'redux';

import { TPayloadAction } from '../../types/redux';

export function makeAction<T>(type: T): Action<T> {
  return { type };
}

export function makePayloadAction<T, P>(type: T, payload: P): TPayloadAction<T, P> {
  return {
    type,
    payload,
  };
}

export function makeActionsFactory<T>() {
  return {
    makeAction: <E extends T>(type: E) => makeAction<E>(type),
    makePayloadAction: <E extends T, P>(type: E, payload: P) => makePayloadAction<E, P>(type, payload),
  };
}

export interface ICombinedActions {
  [N: string]: Action<any> | ((...args: any[]) => TPayloadAction<any, any>);
}

export type TGetCombinedActionTypes<T extends ICombinedActions> = {
  [N in keyof T]: T[N] extends (...args: any[]) => TPayloadAction<any, any> ? ReturnType<T[N]> : T[N]
}[keyof T];
