import React from 'react';
import cn from 'classnames';

import { PageBlocksContext } from '../../contexts/PageBlocksContext';
import { useRenderBlocksContext } from '../../contexts/RenderBlocksContext';
import { TUpdatedContentBlock } from '../../utils/convertBlocksStructure';

import styles from './linesContentView.pcss';

export const LinesContentView = (props: TUpdatedContentBlock) => {
  const { children } = props;
  const renderBlocks = useRenderBlocksContext();

  if (children && children.length > 0) {
    return (
      <div className={cn(styles.linesContentView,
        children.length > 1 && styles.linesContentViewGrid)}
      >
        {/* eslint-disable react/no-array-index-key */}
        {children.flat().map((childBlock, index) => (
          <PageBlocksContext.Provider key={index} value={1}>
            {renderBlocks(childBlock)}
          </PageBlocksContext.Provider>
        ))}
      </div>
    );
  }

  return null;
};
