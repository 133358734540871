import { connect } from 'react-redux';

import { Footer } from '../../components/Footer';
import { IAppState } from '../../reducers';
import { getDomain, getLang } from '../../utils/commonSelectors';

interface IFooterContainerProps {
  homeLink: { en: string; ru: string };
  queryLangSwitch?: boolean;
}

function mapStateToProps(state: IAppState, ownProps: IFooterContainerProps) {
  const lang = getLang(state);
  const domain = getDomain(state);

  return {
    lang,
    domain,
    ...ownProps,
  };
}

export const FooterContainer = connect(mapStateToProps)(Footer);
