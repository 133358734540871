import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import { camelInterceptor } from './camelInterceptor';
import { responseLogsInterceptor } from './logsInterceptor';

const baseClient = (baseURL: string) => axios.create({
  baseURL,
  headers: {
    'Content-type': 'application/json',
    'X-LANGUAGE': 'ru',
  },
});

export interface IBaseClientConfig {
  cookies?: string;
  baseURL: string;
}

export abstract class BaseClient<
  IGetRequests extends {},
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  IPostRequests extends { [N in keyof IPostRequests]: IPostApiRecord<any, any> },
> {
  private readonly axios: AxiosInstance;

  private readonly cookies: string;

  public constructor({ cookies, baseURL }: IBaseClientConfig) {
    this.cookies = cookies || '';
    this.axios = baseClient(baseURL);
    this.setupInterceptors();
  }

  public get session(): string {
    return (/session=(.*);?/.exec(this.cookies) || [])[1];
  }

  public get<T extends keyof IGetRequests>(url: T, config?: AxiosRequestConfig) {
    return this.axios.get<IGetRequests[T]>(url as string, this.enrichConfiguration(config));
  }

  public post<T extends keyof IPostRequests>(
    url: T,
    data: IPostRequests[T]['request'],
    config?: AxiosRequestConfig,
  ) {
    return this.axios.post<IPostRequests[T]['response']>(url as string, data, this.enrichConfiguration(config));
  }

  protected responseInterceptors(): Array<(r: AxiosResponse) => AxiosResponse> {
    return [];
  }

  protected requestInterceptors(): Array<(r: AxiosRequestConfig) => AxiosRequestConfig> {
    return [];
  }

  private requiredResponseInterceptors(): Array<(r: AxiosResponse) => AxiosResponse> {
    return [
      camelInterceptor,
      responseLogsInterceptor,
    ];
  }

  private requiredRequestInterceptors(): Array<(r: AxiosRequestConfig) => AxiosRequestConfig> {
    return [];
  }

  private enrichConfiguration(config: AxiosRequestConfig = {}) {
    if (!this.cookies || process.env.IS_CLIENT) {
      return config;
    }

    return {
      ...config,
      headers: {
        Cookie: this.cookies,
        ...config.headers,
      },
    };
  }

  private setupInterceptors() {
    this
      .requiredResponseInterceptors()
      .concat(this.responseInterceptors())
      .forEach((interceptor) => {
        this.axios.interceptors.response.use(interceptor);
      });

    this
      .requiredRequestInterceptors()
      .concat(this.requestInterceptors())
      .forEach((interceptor) => {
        this.axios.interceptors.request.use(interceptor);
      });
  }
}
