import React from 'react';

import { useRenderBlocksContext } from '../../contexts/RenderBlocksContext';
import { TUpdatedContentBlock } from '../../utils/convertBlocksStructure';
import { ContentWrapper } from '../ContentWrapper';

import styles from './expiredContent.pcss';

interface IExpiredContentProps {
  blocks: TUpdatedContentBlock[];
}

/**
  Блок для отображения контента страницы между хедером и футером, нужен для прижатия футера к низу страницы
*/
export const ExpiredContent = (props: IExpiredContentProps) => {
  const { blocks } = props;
  const renderBlocks = useRenderBlocksContext();

  return (
    <div className={styles.expiredContent}>
      {blocks && blocks.length > 0 && (
        <ContentWrapper>
          {blocks.map((block) => renderBlocks(block))}
        </ContentWrapper>
      )}
    </div>
  );
};
