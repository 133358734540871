/**
 * Удаляет из раздела API конфигурации параметры сервер и client в зависимости о среды
 */
export type TConfig = {
  api: Record<string, { server: string; client: string }>;
  homeLink: { ru: string; en: string };
};

export type TSanitizedConfig = {
  api: Record<string, string>;
  homeLink: { ru: string; en: string };
  queryLangSwitch?: boolean;
};

// eslint-disable-next-line
export function sanitizeConfig<T extends TConfig>(config: T, forClient: boolean = false): TSanitizedConfig {
  const apiConf = Object.keys(config.api).reduce<Record<string, string>>((acc, key) => ({
    ...acc,
    [key]: !forClient ? config.api[key]!.server : config.api[key]!.client,
  }), {} as Record<string, string>);

  return {
    ...config,
    api: apiConf,
    homeLink: config.homeLink,
  };
}
