import punycode from 'punycode/';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Lang, PageMeta, PageOpenGraph } from '@rcdev/api-bff-parking';

import { convertBlocksStructure, TUpdatedContentBlock } from '../../utils/convertBlocksStructure';
import { IAppState, IAsyncThunkConfig } from '../types';

export interface IExpiredPageProgs {
  meta: PageMeta;
  openGraph: PageOpenGraph;
  blocks: TUpdatedContentBlock[];
}

export type TInitialParametersState = {
  lang: Lang;
  domain: string | undefined;
  isLoading: boolean;
  page?: IExpiredPageProgs;
  pageError?: {
    code: number;
    message: string;
    stack: string;
  };
};

const initialState: TInitialParametersState = {
  lang: Lang.Ru,
  domain: undefined,
  isLoading: false,
  page: {} as IExpiredPageProgs,
};

export const getExpiredContent = createAsyncThunk<IExpiredPageProgs, void, { state: IAppState }>(
  '/api/v1/expired/page',
  async (_, _extraArgument) => {
    const state: IAppState = _extraArgument.getState();
    const { domain, lang } = state.initialParameters;
    if (!domain) {
      throw new Error('Domain is undefined');
    }

    const response = await
    (_extraArgument as unknown as IAsyncThunkConfig).extra?.expiredApi.getExpiredPage(domain, lang);

    if (response) {
      const { meta, openGraph, blocks } = response.data.page;
      const updatedBlocksStructure = convertBlocksStructure(blocks);

      const updatedResponse = {
        meta,
        openGraph,
        blocks: updatedBlocksStructure,
      };

      return updatedResponse;
    }

    throw new Error('response of getExpiredContent is undefined');
  },
);

export const initialParametersSlice = createSlice({
  name: 'initialParameters',
  initialState,
  reducers: {
    setData(state: TInitialParametersState, action: PayloadAction<Partial<TInitialParametersState>>) {
      return {
        ...state,
        ...action.payload,
        domain: action.payload.domain ? punycode.toASCII(action.payload.domain.toLocaleLowerCase()) : undefined,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getExpiredContent.pending, (state) => ({
      ...state,
      isLoading: true,
    }));
    builder.addCase(getExpiredContent.fulfilled, (state, action: PayloadAction<IExpiredPageProgs>) => ({
      ...state,
      isLoading: false,
      page: action.payload,
    }));
    builder.addCase(getExpiredContent.rejected, (state, payload) => {
      let code = 500;
      if (payload.error.message?.startsWith('Request failed with status code ')) {
        const parsedCode = parseInt(payload.error.message.slice(-3), 10);
        if (!Number.isNaN(parsedCode)) {
          code = parsedCode;
        }
      }

      return {
        ...state,
        pageError: {
          code,
          message: payload.error?.message || '',
          stack: payload.error?.stack || '',
        },
        isLoading: false,
      };
    });
  },
});

export const { setData } = initialParametersSlice.actions;

export default initialParametersSlice.reducer;
