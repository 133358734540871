/* eslint-disable react/no-danger */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

export interface IScriptProps {
  page: any;
  id: string;
  pageProps: any;
}

export function Scripts({ pageProps, id, page }: IScriptProps): JSX.Element {
  const getFirst = (a: string | string[]): string => (Array.isArray(a) ? a[0] : a);
  const stringify = (value: unknown) => JSON.stringify(value).replace('</script>', '<\\/script>');
  let scriptNameRegular = new RegExp(`^${page.displayName}\\.(js|css)$`);

  if (process.env.NODE_ENV === 'development') {
    scriptNameRegular = new RegExp(`^${page.displayName.replace('HotExported', '')}\\.js$`);
  }

  const scripts = Object
    .keys(pageProps.manifest || {})
    .filter((scriptName) => scriptNameRegular.test(scriptName) && !scriptName.endsWith('.map'));

  return (
    <>
      <script
        type="application/json"
        id={`__${id}_PAGE_PROPS__`}
        dangerouslySetInnerHTML={{ __html: stringify(pageProps) }}
      />
      {scripts.filter((s) => s.endsWith('.js')).map((script) => (
        <script async key={script} src={`${getFirst(pageProps.manifest[script])}`} />
      ))}
      {scripts.filter((s) => s.endsWith('.css')).map((script) => (
        <link rel="stylesheet" href={`${getFirst(pageProps.manifest[script])}`} />
      ))}
    </>
  );
}
