import React from 'react';
import cn from 'classnames';
import { Button } from '@runity/ui-button';
import { Link as UILink } from '@runity/ui-link';
import { Text } from '@runity/ui-text';
import { Link as ILink, LinkView } from '@rcdev/api-bff-parking';

import styles from './link.pcss';

type TSize = 's' | 'xs' | 'm' | 'l';

interface ILinkProps extends ILink {
  size?: TSize;
}

const getButtonStyles = (view: LinkView) => {
  switch (view) {
    case 'buttonOutlined':
      return 'secondary';
    case 'buttonFilled':
    default:
      return 'primary';
  }
};

export const Link = ({ size = 'm', ...link }: ILinkProps) => {
  const { view, title, url, target } = link;

  if (view === 'default') {
    return (
      <UILink href={url} target={target} className={styles.defaultLink}>
        <Text preset="textLarge">{title}</Text>
      </UILink>);
  }

  return (
    <Button color={getButtonStyles(view)} size={size}>
      <UILink
        className={cn(styles.linkInBtn, styles[getButtonStyles(view)])}
        href={url}
        target={target}
      >
        {title}
      </UILink>
    </Button>
  );
};
